(function($) {

	if ($('#cookie-notification').length && Cookies.get('cookie-notification') !== 'true') {
		$('#cookie-notification').show();

		$('.cookie_close').on('click', function(e) {
			e.preventDefault();
			Cookies.set('cookie-notification', 'true', { expires : 7 });
			$('#cookie-notification').fadeOut(speed, ease);
		});
	} else
		$('#cookie-notification').hide();

}(jQuery));
