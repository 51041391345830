/* ######################################################################################################################## */
/* GENERIC VARS ########################################################################################################### */
/* ######################################################################################################################## */
var speed = 300,
	ease = 'easeInOutQuart';

/* ######################################################################################################################## */
/* SCRIPTS (WINDOW ONLOAD) ################################################################################################ */
/* ######################################################################################################################## */
$(window).load(function() {
});

/* ######################################################################################################################## */
/* SCRIPTS (DOCUMENT READY) ############################################################################################### */
/* ######################################################################################################################## */
$(document).ready(function() {

	// Object Fit Polyfill
	objectFitImages();

	// Default anchor behaviour
	$('a[href="#"]').on('click', function(e) {
		e.preventDefault();
	});

	// goTo ID
	$('body').delegate('.js-goTo', 'click', function(e) {
		e.preventDefault();
		$(this).blur();

		var idDiv = $(this).attr('data-goto');
		var offsetTop = $(idDiv).offset().top;
		var headerH = $('#header').outerHeight(true);

		$('html, body').animate({
			scrollTop : offsetTop - headerH
		}, speed * 2, ease);
	});
});

/* ######################################################################################################################## */
/* SCRIPTS (WINDOW RESIZE) ################################################################################################ */
/* ######################################################################################################################## */
$(window).bind('resizeEnd', function() {
});

// resizeEnd
$(window).resize(function() {
	if (this.resizeTO)
		clearTimeout(this.resizeTO);

	this.resizeTO = setTimeout(function() {
		$(this).trigger('resizeEnd');
	}, 100);
});

/* ######################################################################################################################## */
/* SCRIPTS (WINDOW SCROLL) ################################################################################################ */
/* ######################################################################################################################## */
$(window).scroll(function() {
});

/* ######################################################################################################################## */
/* FUNCTIONS (LOCK / UNLOCK SCROLL) ####################################################################################### */
/* ######################################################################################################################## */
function lockScroll() {
	$('html').attr('data-scroll-current', $(window).scrollTop());
	window.addEventListener('scroll', noscroll);
}

function unlockScroll() {
	window.removeEventListener('scroll', noscroll);
}

function noscroll() {
	$(window).scrollTop($('html').attr('data-scroll-current'));
}
