(function($) {

	var speed = 300,
		ease = 'easeInOutQuart';

	$('form').each(function() {
		var $this = $(this);

		if ($this.hasClass('js-browser-validation'))
			$this.attr('novalidate', 'novalidate');
	});

	$('input, select, textarea').not('.js-input-novalidate').change(function() {
		var $this = $(this);

		if ($this.parents('form').hasClass('js-browser-validation'))
			$this.validate();
	});

	$('form').submit(function(e) {
		var $this = $(this);

		$this.find('.input-submit').addClass('is-submitting');

		if ($this.hasClass('js-browser-validation')) {
			$this.find('input, select, textarea').validate();

			if ($this.find('.js-field.is-invalid').length) {
				e.preventDefault();

				$this.find('.js-alert.is-invalid').slideDown(speed, ease);

				setTimeout(function() {
					$('.js-modal_wrapper').each(function() {
						var idModal = '#' + $(this).attr('id');

						checkModalHeight(idModal);
					});
				}, speed);

				$('.is-invalid', $this).find('input, select, textarea').first().focus();
				$this.find('.input-submit').removeClass('is-submitting');
			} else {
				$this.find('.js-alert.is-invalid').slideUp(speed, ease);

				$recaptcha = $this.find('.g-recaptcha');

				if ($recaptcha.length) {
					e.preventDefault();

					var recaptchaId = $recaptcha.data('recaptcha-id');
					currentRecaptchaId = recaptchaId;

					if (typeof grecaptcha === 'object')
						grecaptcha.execute(recaptchaId);
				} else
					this.submit();
			}
		}
	});

	$.fn.validate = function() {
		return $(this).each(function() {
			var $field = $(this).closest('.js-field'),
				$fieldFeedback = $field.find('.field-feedback'),
				validity = $(this).prop('validity');

			$field.removeClass('is-valid is-invalid');

			if (validity.valid === true)
				$field.addClass('is-valid');
			else if (validity.valid === false) {
				var validationMsg = $fieldFeedback.data('validation-msg');

				if (!$fieldFeedback.data('validation-msg')) {
					var message = 'There is a problem with the data inputted';

					if (validity.valueMissing === true)
						message = 'This is a required field';
					else if (validity.typeMismatch === true && $(this).attr('type') === 'email')
						message = 'Please enter a valid email address';
					else if (validity.typeMismatch === true && $(this).attr('type') === 'url')
						message = 'Please enter a valid website address';
					else if (validity.tooShort === true)
						message = 'Must be a minimum of ' + $(this).attr('minlength') + ' characters';
					else if (validity.tooLong === true)
						message = 'Must be a maximum of ' + $(this).attr('maxLength') + ' characters';

					$fieldFeedback.html('<span>' + message + '</span>');
				} else
					$fieldFeedback.html('<span>' + validationMsg + '</span>');

				$field.addClass('is-invalid');
			}
		});
	};

}(jQuery));
