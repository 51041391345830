(function($) {

	$('body').delegate('.js-panel_trigger', 'click', function(e) {
		var $this = $(this),
			panel = '#' + $this.attr('data-panel');

		$this.toggleClass('is-active');
		$this.blur();

		if ($('.js-panel').not(panel).hasClass('is-active')) {
			unlockScroll();
			$('.js-panel').not(panel).removeClass('is-active');
		}

		$(panel).toggleClass('is-active');

		if ($('.js-panel').hasClass('is-active'))
			lockScroll();
		else
			unlockScroll();
	});

	$(window).bind('resizeEnd', function() {
		if ($(window).width() > 768) {
			if ($('.js-panel').hasClass('is-active')) {
				unlockScroll();
				$('.js-panel').removeClass('is-active');
			}
		}
	});

	$(document)
		.mouseup(function(e) {
			if ($('.js-panel.is-active').length > 0) {
				var container = $('.js-panel-container'),
					trigger = $('.js-panel_trigger');

				if ((!container.is(e.target) && container.has(e.target).length === 0) && (!trigger.is(e.target) && trigger.has(e.target).length === 0)) {
					unlockScroll();
					trigger.removeClass('is-active');
					$('.js-panel').removeClass('is-active');
				}
			}
		})
		.keyup(function(e) {
			if ($('.js-panel.is-active').length > 0) {
				if (e.keyCode == 27) {
					unlockScroll();
					$('.js-panel_trigger, .js-panel').removeClass('is-active');
				}
			}
		});

}(jQuery));
